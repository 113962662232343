/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'; 
import QS from 'qs';
import createStore from '@/store';
import { clearStorage } from '@/common/tools';
const iParams = {
    'token': createStore.getters.token,
    'uid': createStore.getters.uid,
    'timestamp': Date.parse(new Date()),
}
// 192.168.1.130
// 环境的切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://api.cixinwenhua.org/api/v1/';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'https://api.cixinwenhua.cn/api/v1/';
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/api/v1';
}

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = iParams.token;
        // token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code==-99){
                clearStorage();
                window.location.href = "/login/-3";
                console.log("请重新登录");
            }else{
                return Promise.resolve(response);
            }
            
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    console.log(error.response.status)
                    break;
                // 403 token过期                
                // 登录过期对用户进行提示                
                // 清除本地token和清空vuex中token对象                
                // 跳转登录页面                
                case 403:
                    console.log(error.response.status)
                    break;
                // 404请求不存在                
                case 404:
                    console.log(error.response.status)
                    break;
                // 其他错误，直接抛出错误提示                
                default:
                    console.log(error.response.status)
            }
            return Promise.reject(error.response);
        }
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    params.token = iParams.token;
    params.uid = iParams.uid;
    params.timestamp = iParams.timestamp;
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.data)
        })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    params.token = iParams.token;
    params.uid = iParams.uid;
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * 同步get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const getAsync = async (url, params)=>{
    try {
        params.token = iParams.token;
        params.uid = iParams.uid;
        params.timestamp = iParams.timestamp;
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    } catch (err) {
        console.log(err)
    }
}

/**
 * 同步post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const postAsync = async (url, params) => {
    try {
        params.token = iParams.token;
        params.uid = iParams.uid;
        return new Promise((resolve, reject) => {
            axios.post(url, QS.stringify(params))
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    } catch (err) {
        console.log(err)
    }
}