export const public_v1 = "http://api.cixinwenhua.org/static/v1/"

//清空客户端缓存
export function clearStorage(){
    localStorage.removeItem('uid');
    localStorage.removeItem('nick_name');
    localStorage.removeItem('icon');
    localStorage.removeItem('token');
    return 1;
}

export function getDate(){
    var date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function changetime(value) {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    //var hourTime = 0;// 小时
    if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if(minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            //hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var time = parseInt(secondTime);
    if(secondTime < 10){
        time = "0" + parseInt(secondTime);
    }
    if(minuteTime < 10) {
        time = "0" + parseInt(minuteTime) + ":" + time;
    }else{
        time = parseInt(minuteTime) + ":" + time;
    }
    return time;
}

