<template>
    <div class="container">
      <div class="top">
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="8">
            <el-image :src="logo">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-col>
          <el-col :span="10">
            <ul>
              <li 
                v-for="(item,index) in navList"
                v-bind:key="item.id"
                class="nav-item"
              ><el-divider direction="vertical" v-if="index != 0"></el-divider><el-link @click="goTo(index)" class="nav-item-link">{{ item.title }}</el-link></li>
            </ul>
          </el-col>
          <el-col :span="6">
            <el-form :inline="true" :model="formInline" class="demo-form-inline app-right app-padding-top-15">
              <el-form-item>
                <el-input v-model="search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button  @click="onSubmit">搜索</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <router-view/>
      <div class="app-footer">
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row type="flex" class="app-footer-row" justify="center">
          <div class="link"><a href="https://beian.miit.gov.cn/">京ICP备2022017402号-1</a></div>
          <el-divider direction="vertical"></el-divider>
          <div class="link">出版物经营许可证：京零字第丰210206号</div>
          <el-divider direction="vertical"></el-divider>
          <div class="link">©2022 北京慈心文化</div>
        </el-row>
      </div>
    </div>
</template>

<script>
import { public_v1 } from '@/common/tools';
export default {
  data() {
    return {
      logo: public_v1 + 'images/logo.png',
      navList:[
        {id:1,title:'首页'},
        // {id:2,title:'资讯'},
        // {id:3,title:'产品介绍'},
        {id:4,title:'关于慈光'}
      ]
    }
  },
  methods: {
    onSubmit(){
    }
  },
  }
</script>
<style>

.top{
  margin:15px 0px;
}
.nav-item{
  float: left;

}
.nav-item-link{
  font-size: 18px;
  padding: 0 10px;
}

.el-form--inline .el-form-item {
    margin-right: 0px!important;
    margin-left: 10px!important;
}
.app-footer{
  padding:20px 30px 80px 30px;
  background-color: #fff;
  
}
.app-footer-row{
  padding:10px 0;
}
</style>
