<template>
  <div >
    <el-row >
      <div class="app-carousel">
        <el-carousel :interval="5000" arrow="always" height="545px">
          <el-carousel-item v-for="item in carouselList" :key="item">
            <el-image :src="item.cover" class="app-carousel-image">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-row>
    <h3 id="yuan-jiao"><i class="el-icon-menu"></i> 慈光出品</h3>
    <el-row :gutter="12">
      <el-col 
        v-for="(item,index) in productList"
        v-bind:key="item.id"
        :span="12">
        <el-card shadow="hover" @cilck="goTo(index)" class="app-margin-bottom-20">
          <el-image :src="item.cover">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          {{ item.title }}
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAudioList, getProductList } from '@/common/api';
export default {
  name: 'HomeView',
  data() {
      return {
        title: "《德育故事》中英文绘本",
        newList:[],
        productList:[],
        isLoading:true,
        carouselList:[
          {id:1,cover:"https://cdn.cixinwenhua.cn/storage/preview/1200/picture/2022/11/723ed93b1c14c76e341d3dc388cf1d03.jpg"}
        ]
      }
  },
  methods: {
    loadData(){
      getAudioList({aid:15,sort:'asc'}).then(res => {
        this.newList = res.data.rows
      }) 
      getProductList({limit:4}).then(res => {
        this.productList = res.data.rows
      }) 
    }, 
    goTo(index){
      console.log(index)
    }
  },
  created () {
    this.loadData()
  }
}
</script>
<style>
.app-carousel{
  margin-bottom: 20px;
}
.app-carousel-image{
  width:1280px;
  object-fit: cover;
}
.app-section-title{
  padding:15px 10px;
  background-color: #fff;
}

</style>